import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFiltersState, useFiltersData } from '@/contexts/filters'
import { getSelectedFilters } from '@/utils/filters'

import FormCheckboxGroup from '@/components/FormCheckboxGroup'

import styles from './FilterOptions.module.scss'

const FilterOptions = ({
    filter = {},
    products,
    isModal = false,
    handleFilterChange = () => { },
}) => {
    const filtersState = useFiltersState()
    const { filterOptions: filterConfig, sortOptions, defaultSort } = useFiltersData()
    const { filterValues, tempFilters, sortByLabel } = filtersState

    const sortValue = useMemo(() => (
        sortOptions.find(sort => sort.value !== defaultSort && sort.label === sortByLabel)?.value
    ), [sortOptions, defaultSort, sortByLabel])

    const tempFilterValues = {}
    Object.keys(tempFilters).forEach(key => {
        tempFilterValues[key] = {
            property: key,
            values: tempFilters[key] || []
        }
    })

    // If we are in the modal we need to take into account the temp values to disable properly
    // on desktop the changes are immediate so its more straightforward
    const filterValuesToCheck = isModal ? tempFilterValues : filterValues
    const checkedValues = isModal ? tempFilterValues[filter.property].values : filtersState[filter.property].values

    const selectedFilters = getSelectedFilters(filterConfig, Object.values(filterValuesToCheck))

    return (
        <FormCheckboxGroup
            dataSrc={filter.options}
            initialValues={checkedValues}
            required={0}
            className={styles.checkBoxGroup}
            checkboxClassName={styles.filterCheckbox}
            onSelectionUpdate={e => handleFilterChange(e, filter.property)}
            sortValue={sortValue}
            filter={filter}
            products={products}
            selectedFilters={selectedFilters}
            isModal={isModal}
        />
    )
}

FilterOptions.propTypes = {
    filter: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    isModal: PropTypes.bool
}

export default FilterOptions
